//import City from "../Images/city.jpg";
//import Salvador from "/Images/salvador.jpg";
//import Ubc from "../Images/ubc.jpeg";

export const images = [
  { title: "LET'S GET YOU STARTED", 
  subtitle: "We help start-ups and small businesses achieve the online presence they need to be successful.", img: "img/nova-web-designers.jpg", 
  btn: "Get a Free Quote", url: "#contact" },

  {
    title: "Article Writing",
    subtitle: "Businesses have to keep a constant flow of traffic to their websites. Drawing the interest of avid readers through blogging helps your business flourish.",
    img: "img/seo-blog-writing.jpg",
    btn: "LEARN MORE",
    url: "#contentwriting"
  },
 
  {
    title: "CPanel Web Hosting",
    subtitle: "Starting at $3.49/Month",
    img: "img/best-cpanel-web-hosting.jpg",
    btn: "See Plans",
    url: "https://quantumdesigners.com/WHMCS/cart.php?gid=1"
  },
  {
    title: "Blog",
    subtitle: "How Small Businesses Can Leverage SEO for Long-Term Success",
    img: "img/blog.jpg",
    btn: "Read More",
    url: "https://quantumdesigners.com/site/"
  },
];